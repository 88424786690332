import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiSmartphone ,FiChevronUp, FiMail, FiCopy } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Custom Logo Design',
        description: 'Our vast variety of custom logo design service give you everything you need to start your business, with 100% SATISFACTION.'
    },
    { 
        icon: <FiSmartphone />,
        title: 'Native/Hybrid Mobile Application',
        description: 'High-performing, feature-packed and built to be both scalable and secure, able to handle any business and IT needs.'
    }
    ,
    {
        icon: <FiLayers />,
        title: 'Website Design',
        description: 'Client-focused, customer-centric, creating website solutions that deliver tangible business results.'
    }
    ,
    {
        icon: <FiLayers />,
        title: 'Website Development',
        description: 'Client-focused, customer-centric, creating website solutions that deliver tangible business results.'
    }
    ,
    {
        icon: <FiCopy />,
        title: 'Copywriting',
        description: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for.'
    },
    {
        icon: <FiCopy />,
        title: '2D/3D Animation',
        description: 'Design Raptor help your brand embrace its challenge through our interactive video animation production.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services provide for you.',
        description = 'We take products through every stage necessary for their creation – Product Discovery, Business and User Research, UX/UI Design, Prototyping, Usability Testing, and future Product Improvements.',
        subtitle= 'What we can do for you';
        return(
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">{subtitle}</span>
                                <h2 className="title">{title}</h2>
                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/service-details">
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
