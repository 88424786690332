import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp , FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Pricing from "./pricing";
import Pricing1 from "./pricing1";
import Pricing2 from "./pricing2";
import Pricing3 from "./pricing3";
import Pricing4 from "./pricing4";
import Pricing5 from "./pricing5";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
const PricingTable = () => {
    return (
        <>
            <PageHelmet pageTitle='Pricing Table' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Pricing Table'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Pricing Tbale Area  */}
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                    <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Pricing Tables</span>
                                    <h2 className="title">How will your business reach ahead?</h2>
                                    <p className="description">Our local teams are working today to create the business of tomorrow. Get in touch and let's find out how we transform your industry, together.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 mt--30">
                 <div className="rn-pricing">
                     <div className="pricing-table-inner">
                         <div className="pricing-header">
                             <h4 className="title">Free</h4>
                             <div className="pricing">
                                 <span className="price">29</span>
                                 <span className="subtitle">USD Per Month</span>
                             </div>
                         </div>
                         <div className="pricing-body">
                             <ul className="list-style--1">
                                 <li><FiCheck /> 5 PPC Campaigns</li>
                                 <li><FiCheck /> Digital Marketing</li>
                                 <li><FiCheck /> Marketing Agency</li>
                                 <li><FiCheck /> Seo Friendly</li>
                                 <li><FiCheck /> UI/UX designs</li>
                             </ul>
                         </div>
                         <div className="pricing-footer">
                             <a className="rn-btn" href="#pricing">Purchase Now</a>
                         </div>
                     </div>
                 </div>
             </div>
             {/* End PRicing Table Area  */}

             {/* Start PRicing Table Area  */}
             <div className="col-lg-4 col-md-6 col-12 mt--30">
                 <div className="rn-pricing active">
                     <div className="pricing-table-inner">
                         <div className="pricing-header">
                             <h4 className="title">Business</h4>
                             <div className="pricing">
                                 <span className="price">29</span>
                                 <span className="subtitle">USD Per Month</span>
                             </div>
                         </div>
                         <div className="pricing-body">
                             <ul className="list-style--1">
                                 <li><FiCheck /> 5 PPC Campaigns</li>
                                 <li><FiCheck /> Digital Marketing</li>
                                 <li><FiCheck /> Marketing Agency</li>
                                 <li><FiCheck /> Seo Friendly</li>
                                 <li><FiCheck /> UI/UX designs</li>
                             </ul>
                         </div>
                         <div className="pricing-footer">
                             <a className="rn-btn" href="#pricing">Purchase Now</a>
                         </div>
                     </div>
                 </div>
             </div>
             {/* End PRicing Table Area  */}

             {/* Start PRicing Table Area  */}
             <div className="col-lg-4 col-md-6 col-12 mt--30">
                 <div className="rn-pricing">
                     <div className="pricing-table-inner">
                         <div className="pricing-header">
                             <h4 className="title">Advanced</h4>
                             <div className="pricing">
                                 <span className="price">29</span>
                                 <span className="subtitle">USD Per Month</span>
                             </div>
                         </div>
                         <div className="pricing-body">
                             <ul className="list-style--1">
                                 <li><FiCheck /> 5 PPC Campaigns</li>
                                 <li><FiCheck /> Digital Marketing</li>
                                 <li><FiCheck /> Marketing Agency</li>
                                 <li><FiCheck /> Seo Friendly</li>
                                 <li><FiCheck /> UI/UX designs</li>
                             </ul>
                         </div>
                         <div className="pricing-footer">
                             <a className="rn-btn" href="#pricing">Purchase Now</a>
                         </div>
                     </div>
                 </div>
             </div>
                       
                       
                      </div>
                            
                        
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </>
        
    )
}
export default PricingTable;