import React, { Component } from "react";
import Portfolio1a from './portfolio1a';
import Portfolio2 from './portfolio2';
import Portfolio3 from './portfolio3';
import Portfolio4 from './portfolio4';
import Portfolio5 from './portfolio5';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/portfolio/portfolio-4.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-5.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-6.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/portfolio-7.jpg" alt="React Creative Agency" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/portfolio-8.jpg" alt="React Creative Agency" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/portfolio-9.jpg" alt="React Creative Agency" />;

const PortfolioListContent = [
    {
        image: Portfolio_image,
        category: 'Development',
        title: 'Web Design',
        description: 'Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.'
    },
    {
        image: Portfolio_image2,
        category: 'Product Design',
        title: 'App Development',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image3,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image4,
        category: 'Web Design',
        title: 'Website Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image5,
        category: 'Application',
        title: 'Web Application',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image6,
        category: 'Photoshop',
        title: 'Photo Editing',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                 <div className="portfolio-area ptb--120 bg_color--1">
                <div className="container">
                
                    <Tabs
      defaultActiveKey="web"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="web" title="Logo Design">
        <Portfolio1a />
      </Tab>
      <Tab eventKey="E-commerce" title="E-Commerce">
        <Portfolio2 />
      </Tab>
      <Tab eventKey="logo" title="Website Design">
        <Portfolio3 />
      </Tab>
      <Tab eventKey="Animated" title="Video Animation">
        <Portfolio4/>
      </Tab>
      <Tab eventKey="Branding" title="Mobile Application">
        <Portfolio5 />
      </Tab>
     
    </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default PortfolioList;